import { environment } from '../../../../environments/environment';
import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import {
  SocialAuthServiceConfig,
  GoogleLoginProvider,
  MicrosoftLoginProvider
} from '@abacritt/angularx-social-login';
import { BASE_ROUTES } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withFetch } from '@angular/common/http';
/*
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
*/

// This file is the main application configuration which defines all of the providers
// that can be reused/injected into other modules.
export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(BASE_ROUTES),
    provideAnimationsAsync(),
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: MicrosoftLoginProvider.PROVIDER_ID,
            provider: new MicrosoftLoginProvider(
              environment.idp.microsoft.clientid,
              {
                authority: environment.idp.microsoft.authority,
                redirect_uri: environment.idp.microsoft.login_redirect_uri,
                logout_redirect_uri: environment.idp.microsoft.logout_redirect_uri,
                prompt: 'select_account', // also "login", "consent", or "none"
              }
            )
          },
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment.idp.google.clientid,
              { oneTapEnabled: false }
            )
          }
        ],
        onError: (e) => {
          console.error(e);
        }
      } as SocialAuthServiceConfig
    },
    provideHttpClient(
      // fetch is a more modern API, compared to XMLHttpRequest otherwise.
      // https://angular.dev/guide/http/setup#withfetch
      withFetch()
    )
    /*,
    { provide: LocationStrategy, useClass: HashLocationStrategy }
    */
  ]
};
