import { Routes } from '@angular/router';

// Guide: https://angular.io/guide/router
// Nesting Children: https://www.angulararchitects.io/en/blog/routing-and-lazy-loading-with-standalone-components/
// Lazy Loading and Routing: https://www.angulararchitects.io/en/blog/routing-and-lazy-loading-with-standalone-components/
// Active Link Tracking: https://angular.io/api/router/RouterLinkActive

export const BASE_ROUTES: Routes = [
    {
        path: '',
        loadChildren: () =>
            import('./auth/auth.routes')
                .then(x => x.AUTH_ROUTES)
    },
    // Root page should also head to the login.
    {
        path: '**',
        redirectTo: '',
        pathMatch: 'full'
    }
];
